<template>
    <div class="door-box" id="door-box">
    <div class="l-b" id="hoverButton" @click="closeChoice"></div>
    <div class="screen bg-or" id="tool-page">
        <div class="tool-box">
            <div :class="nowClickAct&&item.id==nowClickAct.id?'tool-unit-act-box tool-unit-box':'tool-unit-box'" v-for="item in toolArrWithIds" :key="item.id" @click="onChoiceStar(item)" @mouseover="onHoverStart(item)" @mouseleave="onHoverEnd">
            <div class="tool-unit-line-bg" :style="`background:${item.lineerBgColor}`"></div>
                <div class="tool-unit-title">{{item.showName}}</div>
            </div>     
        </div>
    <span class="tool-title" >工具页</span>
    <div class="tool-content-box" id="tool-content-box" v-if="nowClickAct">
    <router-view></router-view>
    </div>
    <div :class="nowClickAct?'msg-box right-msg-box':'msg-box'" v-if="!nowClickAct&&nowAct!={}">
        <span class="tool-act-title">{{nowAct.name}}</span>
        <span class="detail-message-page">
            {{nowAct.msg}}
        </span>
    </div>  
    </div>
    <div class="screen bg-or" id="example-page">样例页</div>
    <div class="screen" id="home-connect">
       <div class="circle-box" id="circle-line"></div>
        <span id="left-text">realC</span>
        <span id="right-text">ontent</span>
    </div>
    </div>
</template>
<style src="./door.scss" lang="scss"></style>
<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';//滚动插件
import { nanoid } from 'nanoid';
import randomGradient from 'random-gradient';
// let timeer=null
gsap.registerPlugin(ScrollTrigger);
export default  {
  name:  'REMPLATE',
 data() {
    return {
    nowShowScreen:0,//当前显示的屏幕 0-初始欢迎页  1-工具页 2-样例页面
    isDeal:false,
    startTouchY:0,
    toolArr:[
     {id:0,name:'二维码生成',showName:'维',msg:'文本生成二维码、支持精度及大小控制',routePath:'qrcode'},  
     {id:1,name:'流媒体播放',showName:'流',msg:'各类型流媒体播放组件',routePath:'streamPlay'},  
    ],
    nowAct:{},
    nowClickAct:null,
    isClick:false
    } 
    },
computed: {
    toolArrWithIds() {
      return this.toolArr.map(item => ({
        ...item,
        id:nanoid(),
        lineerBgColor:randomGradient(nanoid()),
      }));
    },
  },
 created() {       
 },
 mounted(){
    let circleLine=document.getElementById('circle-line')
    console.log('circleLine',circleLine)
    gsap.to(circleLine, {
      boxShadow: "0px 0px 80px #ffffff", // 发光效果
      duration: 2, // 动画持续时间
      repeat: -1, // 无限循环
      yoyo: true, // 动画来回
      ease: "power1.inOut", // 平滑效果
    });
    this.initEventListen()
    setTimeout(()=>{
        console.log('this.toolArrWithIds',this.toolArrWithIds);
    },1000)
 },
 methods: {
    closeChoice(){
        gsap.to('#tool-content-box',{direction:30,x:'900',opacity:0})
        this.$nextTick(()=>{
            setTimeout(()=>{
            this.nowClickAct=null
            },300)
        })
    },
    onChoiceStar(unit){
        console.log('unitis',unit)
        this.nowAct={}
        this.nowClickAct=unit
        this.$router.push(`/${unit.routePath}`); 
        this.$nextTick(()=>{
            gsap.from('#tool-content-box',{direction:30,x:'-300',opacity:0})
        })
    },
    onHoverStart(unit){
        this.nowAct=unit
    },
    onHoverEnd(){
        console.log('当前鼠标悬浮改变的',this.isClick)
        this.nowAct={}
    },
    initEventListen(){//初始化监听事件
        let doorBox=document.getElementById('door-box')
        let _this=this
        doorBox.addEventListener("wheel", (event) => {
            if(_this.isDeal==false){
                _this.isDeal=true  
                setTimeout(()=>{
                    _this.isDeal=false  
                },300) 
                _this.movePage(event.deltaY)            
            }
        });
        // 监听触摸开始事件
        doorBox.addEventListener("touchstart", (event) => {
            _this.startTouchY = event.touches[0].clientY;  // 获取触摸开始时的 Y 坐标
        });

        // 监听触摸移动事件
        doorBox.addEventListener("touchmove", (event) => {
            // 仅在滑动时处理
            if (_this.isDeal == false) {
                const deltaY = _this.startTouchY - event.touches[0].clientY; // 计算滑动距离
                if (Math.abs(deltaY) > 10) {  // 设置一个阈值防止轻微滑动触发
                    _this.isDeal = true;
                    setTimeout(() => {
                        _this.isDeal = false;
                    }, 300);
                    _this.movePage(deltaY);
                    _this.startTouchY = event.touches[0].clientY;  // 更新起始触摸点
                }
            }
        });
    },
    movePage(moveY){//根据鼠标动作移动页面
        let _this=this
        if (moveY > 0) {
        if(_this.nowShowScreen==0){//当前展示页面为初始页-切至工具页
        console.log("向下滚动1");
        gsap.to('#left-text',{direction:30,x:'-300',opacity:0})
        gsap.to('#right-text',{direction:30,x:'300',opacity:0})
        gsap.to('#home-connect',{direction:30,opacity:0})
        gsap.to('#circle-line',{direction:30,scale:1.5,opacity:1})
        //将目标屏幕展示出来
        gsap.to('#tool-page',{direction:30,opacity:1})  
        _this.nowShowScreen=1
        console.log('_this.nowShowScree',_this.nowShowScreen)
        }else if(_this.nowShowScreen==2){//当前为样例页-切换至初始页
        console.log("向下滚动2");
        gsap.to('#left-text',{direction:30,x:'0',opacity:1})
        gsap.to('#right-text',{direction:30,x:'0',opacity:1})
        gsap.to('#home-connect',{direction:30,opacity:1})
        gsap.to('#tool-page',{direction:30,opacity:0}) 
        gsap.to('#example-page',{direction:30,opacity:0})  
        gsap.to('#circle-line',{direction:30,scale:1,opacity:0.5})
        _this.nowShowScreen=0
        }
      } else {
        console.log('_this.nowShowScree111',_this.nowShowScreen)
        if(_this.nowShowScreen==0){//当前展示页面为初始页-切至样例页
        console.log("向上滚动1");
        gsap.to('#left-text',{direction:30,x:'-300',opacity:0})
        gsap.to('#right-text',{direction:30,x:'300',opacity:0})
        gsap.to('#home-connect',{direction:30,opacity:0})
        gsap.to('#circle-line',{direction:30,scale:1.5,opacity:1})
        //将目标屏幕展示出来
        gsap.to('#example-page',{direction:30,opacity:1})  
        _this.nowShowScreen=2
        }else if(_this.nowShowScreen==1){
        console.log("向上滚动2");
        gsap.to('#left-text',{direction:30,x:'0',opacity:1})
        gsap.to('#right-text',{direction:30,x:'0',opacity:1})
        gsap.to('#home-connect',{direction:30,opacity:1})
        gsap.to('#tool-page',{direction:30,opacity:0}) 
        gsap.to('#example-page',{direction:30,opacity:0})  
        gsap.to('#circle-line',{direction:30,scale:1,opacity:0.5})
        _this.nowShowScreen=0
        }
        // 执行向上滚动时的逻辑
    }
    }

 }
}
</script>