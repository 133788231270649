import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

//引入antdesign
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入样式

createApp(App)
  .use(router)
  .use(ElementPlus)
  .mount('#app');