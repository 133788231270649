import { createRouter, createWebHistory } from 'vue-router';

// 定义路由规则
const routes = [
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('@/views/qrcode.vue'), // 懒加载
  },
  {
    path: '/streamPlay',
    name: 'streamPlay',
    component: () => import('@/views/streamPlay.vue'), // 懒加载
  },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 模式
  routes,
});

export default router;